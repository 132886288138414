<template>
    <div class="py-4 shape_block">
        <h4 class="mb-4 text-center font-active">Shop By Shape</h4>
        <div>
            <swiper 
                v-bind="swiperOptions"
            >
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink :to="localePath({ name: 'loose-listing', params: { start_with: 'round-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/round.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/round.jpg"
                                    alt="Round Cut Diamonds">
                            </picture>
                            <span>round</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink :to="localePath({ name: 'loose-listing', params: { start_with: 'oval-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/oval.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/oval.jpg"
                                    alt="Oval Cut Diamonds">
                            </picture>
                            <span>oval</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink :to="localePath({ name: 'loose-listing', params: { start_with: 'pear-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/pear.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/pear.jpg"
                                    alt="Pear Cut Diamonds">
                            </picture>
                            <span>pear</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink
                            :to="localePath({ name: 'loose-listing', params: { start_with: 'cushion-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/cushion.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/cushion.jpg"
                                    alt="Cushion Cut Diamonds">
                            </picture>
                            <span>cushion</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink
                            :to="localePath({ name: 'loose-listing', params: { start_with: 'princess-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/princess.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/princess.jpg"
                                    alt="Princess Cut Diamonds">
                            </picture>
                            <span>princess</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink
                            :to="localePath({ name: 'loose-listing', params: { start_with: 'radiant-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/radiant.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/radiant.jpg"
                                    alt="Radiant Cut Diamonds">
                            </picture>
                            <span>radiant</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink
                            :to="localePath({ name: 'loose-listing', params: { start_with: 'emerald-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/emerald.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/emerald.jpg"
                                    alt="Emerald Cut Diamonds">
                            </picture>
                            <span>emerald</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink :to="localePath({ name: 'loose-listing', params: { start_with: 'heart-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/heart.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/heart.jpg"
                                    alt="Heart Cut Diamonds">
                            </picture>
                            <span>heart</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink
                            :to="localePath({ name: 'loose-listing', params: { start_with: 'asscher-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/asscher.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/asscher.jpg"
                                    alt="Asscher Cut Diamonds">
                            </picture>
                            <span>asscher</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide_box">
                        <NuxtLink
                            :to="localePath({ name: 'loose-listing', params: { start_with: 'marquise-cut-diamond' } })">
                            <picture>
                                <source width="64" height="64" type="image/webp" srcset="~/assets/shapes/marquise.webp">
                                <img width="64" height="64" type="image/jpeg" src="~/assets/shapes/marquise.jpg"
                                    alt="Marquise Cut Diamonds">
                            </picture>
                            <span>marquise</span>
                        </NuxtLink>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script setup>
    const localePath = useLocalePath();

    const swiperOptions = {
        // loop:true,
        spaceBetween: 20,
        modules: [SwiperAutoplay],
        centerInsufficientSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        breakpoints: {
            0: {
                slidesPerView: 3,
                slidesPerGroup: 1,
            },
            512: {
                slidesPerView: 4,
            },

            768: {
                slidesPerView: 5,
            },

           1400:{
               slidesPerView: 10,
           }
        },
    };

</script>

<style scoped>
.slide_box {
    padding: 7% 2%;
    text-align: center;
}

.shape_block {
    background: transparent !important;
}

button {
    background: #2a4437;
    color: #fff;
    border-color: #2a4437;
}

a {
    display: inline-block;
}

h4 {
    font-size: 30px;
}

p {
    font-weight: 500;
    color: #6a6161;
}

.slide_box span {
    display: block;
    text-align: center;
    color: #000;
    text-transform: capitalize;
    font-size: 16px;
    margin-top: 15px;
    font-weight: 500;
}

@media(max-width: 992px) {
    p br {
        display: none;
    }
}

@media(max-width: 767px) {
    .test_block {
        margin: 7% 0;
    }

    h4 {
        font-size: 24px;
    }

    .shape_block {
        padding: 0;
    }

    p {
        font-size: 16px;
    }
}

/* -----animation-------- */
img {
    transition: 0.5s;
}

.slide_box:hover img {
    transform: scale(1.1);
    transition: 0.5s;
}

.slide_box:hover span {
    color: var(--green);
}</style>